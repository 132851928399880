<template>
    <div class="product-info-drawer">
        <el-drawer :visible.sync="drawer"
            direction="rtl"
            size="510px"
            :show-close="false"
            :modal="false"
            custom-class="product-drawer"
            @close="close">
            <div slot="title" class="title-wrap">
                <div class="close-btn" @click="close">
                    <img src="@/assets/rap/drawer-close-img.svg" alt="">
                </div>
            </div>
            <div class="content-wrap" v-if="product">
                <div class="title">{{ product.skuName }}</div>
                <div class="image-wrap">
                    <template v-for="(url, index) in product.images">
                        <div class="img" :key="index" v-if="index < 4" @click="showPreviewImg">
                            <rap-image :src="url"></rap-image>
                        </div>
                    </template>
                </div>
                <!-- 价格 -->
                <div class="table-wrap">
                    <div class="price-wrap">
                        <div class="item-wrap"><span class="name">Price</span></div>
                        <div class="item-wrap" v-if="product.shopifyPriceString">
                            Shopify Price: <span class="name">{{ product.shopifyPriceString }}</span>
                        </div>
                        <div class="item-wrap" v-if="product.sellPriceString">
                            Product Cost: <span class="name">{{ product.sellPriceString }}</span>
                        </div>
                    </div>
                    <div class="tips">
                        <p>Product cost – amount you will pay to supplier. </p>
                        <p>Shopify price – product selling price paid by your customers.</p>
                    </div>
                </div>
                <!-- 供应商 -->
                <div class="table-wrap">
                    <div class="item-wrap"><span class="name">Supplier</span></div>
                    <div class="item-wrap">{{ product.supplierName }}</div>
                </div>
                <!-- 变体 | 库存 -->
                <div class="table-wrap">
                    <div class="item-wrap"><span class="name">Variants</span></div>
                    <div class="item-wrap" v-if="product.variants">
                        Variants: <span class="name">{{ product.variants }}</span>
                    </div>
                    <div class="item-wrap" v-if="product.stock">
                        In stock: <span class="name">{{ product.stock }}</span>
                    </div>
                </div>
                <el-button type="danger" size="''" @click="doRemove">Remove</el-button>
            </div>
        </el-drawer>
        <rap-image-preview
            v-if="product"
            :visible.sync="showPreview"
            :dataList="product.images"
            @close="$emit('update:visible', true)">
        </rap-image-preview>
    </div>
</template>
<script>
export default {
    props: ['product', 'visible'],
    data () {
        return {
            drawer: true,
            showPreview: false,
            loading: false
        }
    },
    watch: {
        visible: {
            handler (val) {
                this.drawer = val
                this.loading = false
            },
            immediate: true
        }
    },
    methods: {
        close () {
            this.$emit('update:visible', false)
        },
        // 展示预览
        showPreviewImg () {
            this.showPreview = true
            this.close()
        },
        // 删除
        doRemove () {
            this.loading = true
            this.$emit('remove', this.product)
        }
    }
}
</script>

<style lang="scss" scoped>
.title-wrap {
    height: 40px;
    padding: 0;
    .close-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        img{
            display: inline-block;
            width: 100%;
            height: 100%;
        }
        &:hover{
            opacity: 0.7;
            transition: all .3s ease-in-out;
        }
    }
}
/deep/ .el-drawer__header {
    padding: 0;
    margin-bottom: 20px;
}
.content-wrap {
    padding: 0 24px 30px 24px;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    .title {
        font-size: 20px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #000000;
        line-height: 28px;
    }
    .image-wrap {
        margin: 20px 0 10px 0;
        display: flex;
        flex-direction: row;
        .img {
            width: 104px;
            height: 104px;
            border: 1px solid $borderColor;
            border-radius: 2px;
            padding: 2px;
            background: white;
            cursor: pointer;
            &:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }
    .table-wrap {
        margin-top: 10px;
        margin-bottom: 20px;
        .item-wrap {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #4d4d4d;
            line-height: 20px;
            padding: 10px 15px;
            border: 1px solid $borderColor;
            &:not(:last-of-type) {
                border-bottom-color: transparent;
            }
            &:first-of-type {
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
            }
            &:last-of-type {
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
            }
            .name {
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #000000;
                line-height: 20px;
            }
        }
        .tips {
            margin-top: 5px;
            opacity: 0.5;
            font-size: 11px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #000000;
            line-height: 16px;
        }
    }
    .el-button{
        width: 100%;
        &.el-button--danger {
            background-color: #FF4D4F;
            border-color: #FF4D4F;
        }
    }
}
</style>
