<template>
    <div class="margin-tb-25 my-product">
        <!-- 查询条件 -->
        <product-search-bar @doSearch="doSearch"></product-search-bar>
        <!-- 产品列表 -->
        <product-data-list ref="productList"></product-data-list>
        <!-- 选中分类 -->
        <category-dialog v-if="false"></category-dialog>
    </div>
</template>

<script>
import ProductSearchBar from '@/views/rap/components/search-bar/product-search-bar.vue'
import ProductDataList from './components/product-data-list'

export default {
    components: {
        ProductSearchBar,
        ProductDataList
    },
    data () {
        return {}
    },
    methods: {
        doSearch (params) {
            this.$refs.productList && this.$refs.productList.doSearch(params)
        }
    }
}
</script>

<style lang="scss" scoped>
.product-wrap{
    // background: white;
}
</style>
