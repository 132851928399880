<template>
    <div class="main-content-wrap product-data-list">
        <div class="select-all">
            <el-checkbox v-model="allChecked"
                :indeterminate="indeterminate"
                @change="handleAllSel">
            </el-checkbox>
            <el-button size="small"
                :type=" selProduct.length ? 'primary' : ''"
                @click="handleBatchRemove">
                Remove selected <span v-if="selProduct.length">({{selProduct.length}})</span>
            </el-button>
        </div>
        <div class="list-wrap" v-loading="loading">
            <transition-group name="el-fade-in">
                <div class="list-item" v-for="item in productList" :key="item.skuId" @click="handleProductInfo(item)">
                    <div class="left">
                        <el-checkbox v-model="item.checked" @change="handleChange" @click.stop.native></el-checkbox>
                        <div class="info-wrap">
                            <rap-image class="img" :src="item.imageUrl"></rap-image>
                            <div class="info">
                                <div class="name">{{ item.skuName || '' }}</div>
                                <div class="supplier">{{ item.supplierName }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="price-wrap">
                            <div>Shopify Price：<span>{{ item.sellPriceString }}</span></div>
                            <div>Product Cost：<span>{{ item.shopifyPriceString }}</span></div>
                        </div>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </transition-group>
        </div>
        <!-- 分页 -->
        <rap-pagination class="page-border" :total="total" :currentPage="pageNum" :pageSize="pageSize" @change="pageChange"></rap-pagination>
        <!-- 详情 -->
        <product-info-drawer :visible.sync="showDrawer"
            :product="choosedItem"
            @remove="handleDrawerRemove">
        </product-info-drawer>
    </div>
</template>

<script>
import ProductInfoDrawer from './product-info-drawer'
export default {
    components: {
        ProductInfoDrawer
    },
    data () {
        return {
            productList: [
                // {
                //     skuId: 1,
                //     skuName: 'PHI VILLA 5 Piece Metal Outdoor Indoor Dining Chairs and Larger Square Table SetPHI VILLA 5 Piece Metal Outdoor Indoor Dining Chairs and Larger Square Table Set',
                //     supplier: 'By Fulfillment',
                //     shopifyPrice: '33.06',
                //     cost: '45.06',
                //     imgUrl: 'https://secure.img1-fg.wfcdn.com/im/70249129/resize-h800-w800%5Ecompr-r85/1214/121473110/Binette+Reversible+Quilt+Set.jpg',
                //     imgLists: ['https://secure.img1-fg.wfcdn.com/im/70249129/resize-h800-w800%5Ecompr-r85/1214/121473110/Binette+Reversible+Quilt+Set.jpg', 'https://secure.img1-fg.wfcdn.com/im/63210742/resize-h800-w800%5Ecompr-r85/1200/120032353/Gymax+30.70%2527%2527+x+39.37%2527%2527+Solid+Wood+Novelty+Sandbox.jpg'],
                //     variants: 101,
                //     stock: 10001
                // },
                // {
                //     skuId: 2,
                //     skuName: 'PHI VILLA 5 Piece Metal Outdoor Indoor Dining Chairs and Larger Square Table SetPHI VILLA 5 Piece Metal Outdoor Indoor Dining Chairs and Larger Square Table Set1',
                //     supplier: 'By Fulfillment1',
                //     shopifyPrice: '33.061',
                //     cost: '45.061',
                //     imgUrl: 'https://secure.img1-fg.wfcdn.com/im/63210742/resize-h800-w800%5Ecompr-r85/1200/120032353/Gymax+30.70%2527%2527+x+39.37%2527%2527+Solid+Wood+Novelty+Sandbox.jpg',
                //     imgLists: ['https://secure.img1-fg.wfcdn.com/im/70249129/resize-h800-w800%5Ecompr-r85/1214/121473110/Binette+Reversible+Quilt+Set.jpg', 'https://secure.img1-fg.wfcdn.com/im/63210742/resize-h800-w800%5Ecompr-r85/1200/120032353/Gymax+30.70%2527%2527+x+39.37%2527%2527+Solid+Wood+Novelty+Sandbox.jpg'],
                //     variants: 10,
                //     stock: 1000
                // }
            ],
            searchForm: {},
            total: 0,
            pageNum: 1,
            pageSize: 24,
            loading: false,
            allChecked: false,
            indeterminate: false,
            choosedItem: null,
            showDrawer: false
        }
    },
    computed: {
        selProduct () {
            return this.productList.filter(p => p.checked)
        }
    },
    created () {
        this.reqProductLists()
    },
    methods: {
        doSearch (params) {
            this.searchForm = { ...params }
            this.reqProductLists()
        },
        /**
         * 请求商品列表
         */
        reqProductLists () {
            const prices = this.searchForm.prices || []
            const params = {
                productName: this.searchForm.productName,
                limit: this.pageSize,
                pageNo: this.pageNum
                // priceEnd: prices[1] || '',
                // priceStart: prices[0] || '',
                // supplierId: this.searchForm.supplierId,
                // brandId: this.searchForm.brandId || '',
                // catId: this.searchForm.catId || '',
            }
            this.loading = true
            this.$api.getMyProducts(params).then(res => {
                this.loading = false
                if (res.code === 0) {
                    this.total = res.data.total || 0
                    this.productList = ((res.data && res.data.rows) || [])
                    this.handleChange()
                }
            }).catch(() => {
                this.loading = false
            })
        },
        // 请求删除
        reqProductRemove (spuIds) {
            this.$api.removeMyProducts({
                spuId: spuIds
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success('Remove Success')
                    this.showDrawer = false
                    this.reqProductLists()
                }
            }).catch(() => {})
        },
        // 单个删除
        handleDrawerRemove (product) {
            this.handleBatchRemove([product.originSpuId])
        },
        // 批量删除
        handleBatchRemove (spuIds) {
            this.$confirm('Are you sure you want to remove this product from your store?', 'Delete this product from my Shopify store too.', {
                customClass: 'remove-store',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Close',
                type: 'warning'
            }).then(() => {
                if (!spuIds || !spuIds.length) {
                    spuIds = this.productList.filter(p => p.checked).map(p => p.originSpuId)
                }
                this.reqProductRemove(spuIds)
            })
        },
        pageChange (data) {
            this.pageNum = data.pageNum
            this.pageSize = data.pageSize
            this.reqProductLists()
        },
        // 全选
        handleAllSel (val) {
            this.indeterminate = false
            this.productList.map(p => {
                if (p.pushStatus !== 1) {
                    this.$set(p, 'checked', val)
                }
            })
        },
        // 单个选择
        handleChange () {
            const checks = this.productList.filter(p => p.checked) || []
            this.indeterminate = false
            this.allChecked = false
            if (checks.length) {
                this.allChecked = !!(this.productList.length === checks.length)
                this.indeterminate = !!(this.productList.length !== checks.length)
            }
        },
        // 查看商品详情
        handleProductInfo (product) {
            this.choosedItem = { ...product }
            this.showDrawer = true
        }
    }
}
</script>

<style lang="scss" scoped>
.product-data-list {
    .select-all {
        padding: 15px 0;
        .el-button{
            margin-left: 15px;
        }
    }
    .list-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px 15px;
        background: white;
        border: 1px solid $borderColor;
        cursor: pointer;
        transition: background .3s ease-in-out;
        &:hover{
            background: rgba($color: #1989fa, $alpha: 0.1);
            transition: background .3s ease-in-out;
        }
        &:not(:last-of-type) {
            border-bottom-color: transparent;
        }
        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 70%;
            .info-wrap{
                display: flex;
                flex-direction: row;
                .img{
                    flex-grow: 0;
                    flex-shrink: 0;
                    width: 65px;
                    height: 65px;
                    margin: 0 15px;
                }
                .name {
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    text-align: left;
                    color: #000000;
                    line-height: 20px;
                }
                .supplier {
                    margin-top: 5px;
                    opacity: 0.7;
                    font-size: 12px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: #000000;
                    line-height: 17px;
                }
            }
        }
        .right {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            flex-grow: 1;
            height: 100%;
            .price-wrap {
                margin-right: 35px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: left;
                color: #4d4d4d;
                line-height: 20px;
                div:not(:last-of-type) {
                    margin-bottom: 10px;
                }
                span{
                    color: #1A1A1A;
                    font-weight: 500;
                }
            }
        }
    }
}
</style>
